import React, { FC } from "react";
import { FormControlLabel, Switch } from "@mui/material";
import { FixMeLater } from "src/types";

interface AllocatorCompanyOptionsProps {
    formData: FixMeLater;
    onFormDataChange: (newData: FixMeLater) => void;
}

const AllocatorCompanyOptions: FC<AllocatorCompanyOptionsProps> = ({
    formData,
    onFormDataChange,
}) => {
    const handleToggleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, checked } = event.target;
        const fieldName = name.split(".")[1];
        onFormDataChange({
            ...formData.jsonOptions,
            [fieldName]: checked,
        });
    };

    return (
        <>
            <FormControlLabel
                control={
                    <Switch
                        checked={
                            formData?.jsonOptions?.selectedASCIIFileFormat
                        }
                        onChange={handleToggleChange}
                        name="allocator.selectedASCIIFileFormat"
                    />
                }
                label="Select the File Format of ASCII File"
            />
        </>
    );
};

export default AllocatorCompanyOptions;
