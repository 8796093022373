import styled from "styled-components";

import { Tab } from "@mui/material";

export interface StyledLayoutPaneTabProps {
    $isSelected?: boolean;
}

export const StyledLayoutPaneTab = styled(Tab).attrs<StyledLayoutPaneTabProps>(
    (props) => ({
        $isSelected: props.$isSelected,
    })
)`
    &.MuiButtonBase-root {
        padding: 0.5rem;
        color: ${({ theme }) => theme.colors.white};
        text-transform: none;
    }

    &.MuiTab-root {
        &.Mui-selected {
            color: ${({ theme }) => theme.colors.white};
        }

        & .MuiChip-root {
            background-color: ${({ theme, $isSelected }) =>
                $isSelected ? theme.palette.secondary : theme.palette.primary};
            color: ${({ theme }) => theme.colors.white};
            border: 1px solid ${({ theme }) => theme.colors.white};
            padding: 0.5rem;
            text-transform: none;
        }
    }
`;
