import { Box } from "@mui/material";
import React from "react";
import { FixMeLater } from "src/types";

export const LayoutPaneTabPanel: React.FC<FixMeLater> = (props) => {
    const { children, selectedTab, value, ...other } = props;

    return (
        <Box
            role="tabpanel"
            hidden={value !== selectedTab}
            id={`tts-tabpanel-${value}`}
            aria-labelledby={`tts-tab-${value}`}
            component="div"
            {...other}
        >
            {value === selectedTab && <Box sx={{ p: 1 }}>{children}</Box>}
        </Box>
    );
};
